import Link from "next/link"
import { useEffect } from "react"
import { Button } from "reactstrap"

import Icon, { IconName } from "@components/common/Icon"
import { useTooltip } from "@services/tooltipHook"

import AlertSymbol from "./AlertSymbol"

interface IProps {
  alert?: boolean
  "aria-label"?: string
  className?: string
  /**
   * Contains a URL to be linked to,
   * or the combination of an URL and a target to open the URL in a separate browser tab.
   */
  href?: string | {
    /**
     * URL of a page
     */
    url: string
    /**
     * Target tab in the browser.
     * Recommendation: use '_blank' to open a link in a new tab.
     *
     * @see https://www.w3schools.com/tags/att_a_target.asp
     */
    target: '_blank' | '_self' | '_parent' | '_top' | string
  }
  id?: string
  tooltipActive?: (active: boolean) => void
  onclick?: (...args: any) => void
  title: string
  icon?: IconName
  buttontext?: string
  tooltipText?: string
  disabled?: boolean
  type?: "submit" | "reset" | "button"
}

/**
 * This component provides a standard-usage of Icons to be displayed, depending on:
 * providing a link -> it uses the Link-component
 * providing a function to be opened on click -> it uses the Button component
 * It always brings a tooltip
 * No extra class needed when displayed as default ActionIcon
 * No size needed, size controlled by rem (text size)
 * The ActionIcon is wrapped around a span element with the id, in order to be identified by the optional tooltip.
 *
 */
const ActionIcon: React.FC<IProps> = (props: IProps) => {
  const {
    alert = false,
    className,
    tooltipActive,
    href,
    id,
    onclick,
    title,
    icon,
    buttontext,
    tooltipText = title,
    disabled,
    type = "button"
  } = props

  const { tooltipTargetRef, ToolTip, tooltipOpen } = useTooltip(tooltipText)

  useEffect(() => {
    if (tooltipActive) {
      tooltipActive(tooltipOpen)
    }
  }, [tooltipOpen, tooltipActive])

  const classNameButton = (
    className
      ? className
      : "actionicon"
  ) + (
      disabled
        ? " disabled"
        : ""
    )

  // all actionicons have a standardsize defined by the css styles
  // but the used Icon component still needs a size
  const size = 25

  return <>
    {href
      ? disabled
        ? <span title={title} ref={tooltipTargetRef}><Icon name={icon} size={size} /></span>
        : <Link href={typeof href === 'string' ? href : href.url}
          ref={tooltipTargetRef}
          className={className ? className : "actionicon"}
          title={title}
          aria-label={props["aria-label"]}
          onClick={e => e.stopPropagation()}
          id={id}
          target={typeof href === 'object' ? href.target : undefined}
        >
          <Icon name={icon} size={size} />
        </Link>
      : onclick
        ? <Button
          aria-label={props["aria-label"]}
          className={classNameButton}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onclick()
          }}
          title={title}
          type={type}
          disabled={disabled}
          innerRef={tooltipTargetRef}
          id={id}
        >
          {icon ? <Icon name={icon} size={size} /> : <span>{buttontext}</span>}
        </Button>
        : <Button
          aria-label={props["aria-label"]}
          className={classNameButton}
          title={title}
          type={type}
          disabled={disabled}
          onClick={e => e.stopPropagation()}
          innerRef={tooltipTargetRef}
          id={id}
        >
          {icon ? <Icon name={icon} size={size} /> : <span>{buttontext}</span>}
        </Button>
    }
    {alert && <AlertSymbol className="news-alert-symbol--on-icon" />}

    {ToolTip}
  </>
}

export default ActionIcon
