// #region usecases

import { Action } from "redux-saga"

import { IProject, IMotivationAndSkills } from "@api/schema"
import { IActionRequest } from "@api/schema/action-requests"
import { IFormikActions } from "@basics/form"

/** *************************************************************************************************
 * This enum defines the usecases around the "action requests".
 *
 * @todo multi add usecase "view", which is needed for invitations
 */
export enum ActionRequestsUsecase {
  /**
   * Accept an action request.
   */
  Accept = "_usecase_accept_action_requests",
  /**
   * Reject an action request.
   */
  Reject = "_usecase_reject_action_requests",
  /**
   * Withdraw an action request.
   */
  Withdraw = "_usecase_withdraw_action_requests",
  /**
   * Delete the action request by the receiver or the sender.
   */
  Delete = "_usecase_delete_action_requests",
  /**
   * PlatformActionRequests
   */
  PlatformActionRequests = "_usecase_platform_action_requests"
}

// #endregion

// #region redux actions

/**
 * Used when an action request is accepted/rejected/withdrawn.
 */
export interface IActionRequestReplyInput {
  /** This message represents the reply message to an action request. */
  message?: string
}

/**
 * Notation specified by the api.
 *
 * Im Backend gibt es diese "zusätzlichen" Rollen nur für die Projektmemberships, welche
 * sich von den MembershipsRole unterscheiden.
 * Der Grund ist, die anderen MembershipsRole haben ihre Werte basierend auf den Namen/"@type" von den
 * UserProjectRoles, denn im Backend gibt es die Klasse ProjectCoordinator, welche eine userObjectRole ist,
 * und durch den Namen der klasse hat der "@type" den Wert "projectcoordinator".
 * @todo multi Ein Wunsche wäre es, dass das Backend die Werte des Enums mit den Klassennamen der UserObjectRoles
 * angleicht oder welche Argumente sprechen dagegen?
 * @see https://futureprojects.atlassian.net/browse/FCP-1786
 */
export enum UpdateMembershipRole {
  Coordinator = 'coordinator',
  Observer = 'observer',
  Planner = 'planner',
}

/**
 * Action type for accepting a project member application.
 */
export interface IMemberRoleInput extends IActionRequestReplyInput {
  role: UpdateMembershipRole
}

/**
 * General action to accept/reject/withdraw an ActionRequest.
 * Note: The token is only used by certain ActionRequests, currently only used by
 * invitiations in the usecases accept and reject the invitations.
 */
export interface IActionRequestReplyAction<
  ActionRequestReplyInputType extends IActionRequestReplyInput
> extends Action<ActionRequestsUsecase> {
  actions: IFormikActions
  actionRequest: IActionRequest
  reply: ActionRequestReplyInputType
  token?: string
}

/**
 * Action type for deleting an action request.
 */
interface IActionRequestDeleteAction extends Action<ActionRequestsUsecase> {
  actions: IFormikActions
  actionRequest: IActionRequest
}

export const acceptActionRequestAction = <ActionRequestReply extends IActionRequestReplyInput = IActionRequestReplyInput>(
  actionRequest: IActionRequest,
  reply: ActionRequestReply,
  actions: IFormikActions,
  token?: string
): IActionRequestReplyAction<ActionRequestReply> => ({
  actionRequest,
  actions,
  reply,
  token,
  type: ActionRequestsUsecase.Accept
})

export const rejectActionRequestAction = (
  actionRequest: IActionRequest,
  reply: IActionRequestReplyInput,
  actions: IFormikActions,
  token?: string
): IActionRequestReplyAction<IActionRequestReplyInput> => ({
  actionRequest,
  actions,
  reply,
  token,
  type: ActionRequestsUsecase.Reject
})

export const withdrawActionRequestAction = (
  actionRequest: IActionRequest,
  reply: IActionRequestReplyInput,
  actions: IFormikActions
): IActionRequestReplyAction<IActionRequestReplyInput> => ({
  actionRequest,
  actions,
  reply,
  type: ActionRequestsUsecase.Withdraw
})

export const deleteActionRequestAction = (
  actionRequest: IActionRequest,
  actions: IFormikActions
): IActionRequestDeleteAction => ({
  actionRequest,
  actions,
  type: ActionRequestsUsecase.Delete
})
// #endregion


export enum ProjectMemberApplicationUsecase {
  Create = "_usecase_create_project_member_application"
}

export interface IApplyForProjectMembershipAction extends Action<ProjectMemberApplicationUsecase> {
  actions: IFormikActions
  project: IProject
  motivationAndSkills: IMotivationAndSkills
}

export const createProjectMemberApplicationAction = (
  motivationAndSkills: IMotivationAndSkills,
  project: IProject,
  actions: IFormikActions
): IApplyForProjectMembershipAction => ({
  actions,
  motivationAndSkills,
  project,
  type: ProjectMemberApplicationUsecase.Create
})

// #endregion
